export const statusLevelItems = {
  NORMAL: "normal",
  NEED_ATTENTION: "need_attention",
  URGENT: "urgent",
};

export const statusConditionDate = {
  BEFORE: "before",
  AFTER: "after",
};

export const statusConditionMilestone = {
  ENTRY_DATE: "entry_date",
  RESIDENCE_STATUS_EXPIRATION_DATE: "residence_status_expiration_date",
  BIRTHDAY: "birthday",
  VISA_EXPIRATION_DATE: "visa_expiration_date",
  PASSED_ORDERS: "passed_orders",
};

export const ObjectType = {
  SYNDICATION: "syndication",
  DISPATCHING_COMPANY: "dispatching_company",
  RECEIVING_FACTORY: "receiving_factory",
  INTERN: "intern",
};
export const ViolateStatus = {
  NEW: "New",
  PROCESSING: "Processing",
  DONE: "Done",
};

export const optionsAddressSpx = [
  { label: "Hà Nội", code: "HA_NOI" },
  { label: "Đà Nẵng", code: "DA_NANG" },
  { label: "Cần Thơ", code: "CAN_THO" },
  { label: "Hồ Chí Minh", code: "HO_CHI_MINH" },
];

export const permissionArray: any = {
  USER: "Người dùng",
  PERMISSION_GROUP: "Nhóm quyền",
  WAREHOUSE: "Kho",
  VIEW_USER: "Xem người dùng",
  CREATE_USER: "Tạo người dùng",
  UPDATE_USER: "Cập nhật người dùng",
  DELETE_USER: "Xóa người dùng",
  VIEW_PERMISSION_GROUP: "Xem nhóm quyền",
  CREATE_PERMISSION_GROUP: "Tạo nhóm quyền",
  UPDATE_PERMISSION_GROUP: "Cập nhật nhóm quyền",
  DELETE_PERMISSION_GROUP: "Xóa nhóm quyền",
  VIEW_WAREHOUSE: "Xem kho",
  CREATE_WAREHOUSE: "Tạo kho",
  UPDATE_WAREHOUSE: "Cập nhật kho",
  DELETE_WAREHOUSE: "Xóa kho",
  VIEW_PACKAGE: "Xem gói",
  CREATE_PACKAGE: "Tạo gói",
  UPDATE_PACKAGE: "Cập nhật gói",
  DELETE_PACKAGE: "Xóa gói",
  IMPORT_EXCEL: "Nhập Excel",
  VIEW_CAR: "Xem xe",
  CREATE_CAR: "Tạo xe",
  UPDATE_CAR: "Cập nhật xe",
  DELETE_CAR: "Xóa xe",
  VIEW_PLANE: "Xem máy bay",
  CREATE_PLANE: "Tạo máy bay",
  UPDATE_PLANE: "Cập nhật máy bay",
  DELETE_PLANE: "Xóa máy bay",
  VIEW_PHASE: "Xem giai đoạn",
  CREATE_PHASE: "Tạo giai đoạn",
  UPDATE_PHASE: "Cập nhật giai đoạn",
  DELETE_PHASE: "Xóa giai đoạn",
  VIEW_SHIPMENT: "Xem lô hàng",
  CREATE_SHIPMENT: "Tạo lô hàng",
  UPDATE_SHIPMENT: "Cập nhật lô hàng",
  DELETE_SHIPMENT: "Xóa lô hàng",
  TAKE_PHOTO: "Chụp ảnh",
  PRINT_LIST: "In bảng kê",
  SENT_EMAIL: "Gửi email",
  VIEW_SHIPMENT_DETAIL: "Xem chi tiết lô hàng",
  CREATE_SHIPMENT_DETAIL: "Tạo chi tiết lô hàng",
  UPDATE_SHIPMENT_DETAIL: "Cập nhật chi tiết lô hàng",
  DELETE_SHIPMENT_DETAIL: "Xóa chi tiết lô hàng",
  VIEW_CONFIG_PHASE: "Xem cấu hình giai đoạn",
  CREATE_CONFIG_PHASE: "Tạo cấu hình giai đoạn",
  UPDATE_CONFIG_PHASE: "Cập nhật cấu hình giai đoạn",
  DELETE_CONFIG_PHASE: "Xóa cấu hình giai đoạn",
  VIEW_REPORT: "Xem báo cáo",
  EXPORT_REPORT: "Xuất báo cáo",
  PACKAGE: "Kiện hàng",
  CAR: "Ô tô",
  PLANE: "Máy bay",
  PHASE: "Giai đoạn",
  SHIPMENT: "Lô hàng",
  SHIPMENT_DETAIL: "Chi tiết lô hàng",
  CONFIG_PHASE: "Cấu hình",
  REPORT: "Báo cáo",
  NOTIFICATION: "Thông báo",
  RECEIVER_NOTIFICATION: "Nhận thông báo",
  CUSTOMER: "Khách hàng",
  VIEW_CUSTOMER: "Xem khách hàng",
  CREATE_CUSTOMER: "Tạo khách hàng",
  UPDATE_CUSTOMER: "Cập nhật khách hàng",
  DELETE_CUSTOMER: "Xóa khách hàng",
  CONTAINER: "Container",
  VIEW_CONTAINER: " Xem container",
  CREATE_CONTAINER: "Tạo container",
  UPDATE_CONTAINER: "Cập nhật container",
  DELETE_CONTAINER: "Xóa container",
};

export const phaseArray: any = {
  BAT_DAU_NHAN_HANG: "Bắt đầu nhận hàng",
  NHAN_HANG_XONG: "Nhận hàng xong",
  XUAT_PHAT_TU_KHO_NHAN: "Xuất phát từ kho nhận",
  DEN_KHO_TRUNG_CHUYEN: "Đến kho trung chuyển",
  GIAO_NHAN_HANG_KHO_TRUNG_CHUYEN: "Giao nhận hàng kho trung chuyển",
  GIAO_NHAN_HANG_KHO_TRUNG_CHUYEN_XONG: "Giao nhận hàng kho trung chuyển xong",
  DEN_KHO_SPX: "Đến kho SPX",
  GIAO_HANG_KHO_SPX: "Giao hàng kho SPX",
  GIAO_HANG_KHO_SPX_XONG: "Giao hàng kho SPX xong",
  KHO_SPX_KY_NHAN: "Kho SPX ký nhận",
  XUAT_PHAT_TU_KHO_TRUNG_CHUYEN: "Xuất phát từ kho trung chuyển",
};

export const packageStatus: any = {
  WAITING: "Chờ xử lý", // chờ xử lý
  IN_TRANSIT: "Đang vận chuyển", // trong quá trình vận chuyển
  DELIVERED: "Đã giao hàng", // đã giao hàng
};

export const optionsPlane = [
  { label: "To", code: "BIG" },
  { label: "Nhỏ", code: "SMALL" },
];
