import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableExpandedRows,
  DataTableRowToggleEvent,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getPlanData } from "../../store/listPackage/slice";
import { formatDateUser } from "../../components/utils";

interface GroupByDate {
  [date: string]: {
    date_delivery: string;
    total_package: number;
    total_package_awating: number;
    total_package_transit: number;
    total_package_delivered: number;
  };
}

interface Warehouse {
  warehouse_id: number;
  warehouse_name: string;
  warehouse_code: string;
  groupByDate: GroupByDate;
}

const convertDateRange = (dateRange: string) => {
  if (!dateRange) return ""; // Return empty string if dateRange is undefined or null

  const dates = dateRange.split("/");

  if (dates.length === 1) {
    // Single date
    return formatSingleDate(dates[0]);
  } else if (dates.length === 2) {
    // Date range
    const [startDate, endDate] = dates;
    return `${formatSingleDate(startDate)}-${formatSingleDate(endDate)}`;
  } else {
    // Invalid format
    return dateRange;
  }
};

const formatSingleDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split("-");
  return `${day}/${month}/${year}`;
};

export const PlanPackage = ({
  startDate,
  endDate,
}: {
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const dispatch = useDispatch();

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    const today = new Date();
    dispatch(
      getPlanData({
        start_date: startDate
          ? formatDateUser(startDate)
          : formatDateUser(today),
        end_date: endDate ? formatDateUser(endDate) : formatDateUser(today),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate]);

  const { planData } = useSelector(
    (state: any) => ({
      planData: state.Package.planData,
    }),
    shallowEqual
  );

  const [expandedRows, setExpandedRows] = useState<
    DataTableExpandedRows | Warehouse[]
  >([]);

  // Nested table to display groupByDate data
  const groupByDateTemplate = (rowData: Warehouse) => {
    const datas = Object.values(rowData.groupByDate);

    console.log(datas);

    return (
      <DataTable
        value={datas}
        // className="nested-table"
        style={{ marginLeft: "8.2rem" }}
        emptyMessage="Không có dữ liệu"
        // showHeaders={false}
        className="package-table nested-table"
      >
        <Column
          field="date_delivery"
          header="Ngày nhận"
          style={{ width: "5%" }}
          body={(rowData) => convertDateRange(rowData.date_delivery)}
        ></Column>
        <Column
          field="total_package"
          header="Tổng số lượng"
          style={{ width: "5%" }}
        ></Column>
        <Column
          field="total_package_awating"
          header="Tổng số kiện chưa chuyển"
          style={{ width: "5%" }}
        ></Column>
        <Column
          field="total_package_transit"
          header="Tổng số kiện đang chuyển"
          style={{ width: "5%" }}
        ></Column>
        <Column
          field="total_package_delivered"
          header="Tổng số kiện đã chuyển"
          style={{ width: "5%" }}
        ></Column>
      </DataTable>
    );
  };

  useEffect(() => {
    if (planData?.data) {
      const allExpanded = planData.data.reduce(
        (acc: { [key: number]: boolean }, warehouse: Warehouse) => {
          acc[warehouse.warehouse_id] = true;
          return acc;
        },
        {}
      );

      setExpandedRows(allExpanded);
    }
  }, [planData]);

  return (
    <div className="card">
      <DataTable
        value={planData?.data}
        expandedRows={expandedRows}
        onRowToggle={(e: DataTableRowToggleEvent) => setExpandedRows(e.data)}
        rowExpansionTemplate={groupByDateTemplate}
        dataKey="warehouse_id"
        className="no-hover-effect"
        emptyMessage="Không có dữ liệu"
        scrollable
        scrollHeight="calc(100vh - 16rem)"
        style={{ height: "calc(100vh - 15rem)" }}
        rowClassName={() => "gray-row"}
      >
        <Column expander style={{ width: "3em" }} />
        <Column
          header="STT"
          body={(rowData, options) => (
            <div className="text-center">{first + options.rowIndex + 1}</div>
          )}
          style={{ width: "5%" }}
          alignHeader={"center"}
          align={"center"}
          frozen
        />
        <Column field="warehouse_name" header="Tên kho hàng"></Column>
        <Column field="warehouse_code" header="Mã kho hàng"></Column>
        <Paginator
          first={first}
          rows={rows}
          // totalRecords={planData?.plans.length}
          onPageChange={onPageChange}
        />
      </DataTable>
    </div>
  );
};
