import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPackageData } from "../../store/listPackage/slice";
import { Toast } from "primereact/toast";
import ModalImportExcel from "./ModalImportExcel";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { changeDateFormat } from "../Plane";
import ModalDetailPackage from "./ModalDetailPackage";
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SelectButton } from "primereact/selectbutton";
import { PlanPackage } from "./PlanPackage";
import { statusTemplate } from "../../components/status";
import { IconPackageCustom } from "../../components/Icon";
import { formatDateUser } from "../../components/utils";
import ModalManageUploadFile from "./ModalManageUploadFile";

export default function Package() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const today = new Date();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [modalDetail, setModalDetail] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [modelOpenImportExcel, setmodelOpenImportExcel] =
    useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [dates, setDates] = useState<[Date, Date] | null>([today, today]);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const switchDetailItems = ["Chi tiết", "Kế hoạch"];
  const [switchDetail, setSwitchDetail] = useState(switchDetailItems[0]);
  const [visible, setVisible] = useState<boolean>(false);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const { packageData, dataUserInfo } = useSelector(
    (state: any) => ({
      packageData: state.Package.packageData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  const rowClassName = (rowData: any, rowIndex: any) => {
    return "custom-row";
  };

  const rowsPerPageOptions = [
    { name: "10", code: 10 },
    { name: "20", code: 20 },
    { name: "30", code: 30 },
    { name: "100", code: 100 },
    { name: "200", code: 200 },
    { name: "500", code: 500 },
    { name: "Tất cả", code: Number(packageData?.total) },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      const query: { [key: string]: string } = {};

      if (inputSearch) query.s_global = inputSearch;
      query.start_date =
        dates && dates[0] ? formatDateUser(dates[0]) : formatDateUser(today);
      query.end_date =
        dates && dates[1] ? formatDateUser(dates[1]) : formatDateUser(today);

      if (Object.keys(query).length > 0) {
        dispatch(
          getPackageData({
            page: (first + rows) / rows,
            limit: rows,
            query: query,
          })
        );
      } else {
        dispatch(getPackageData({ page: (first + rows) / rows, limit: rows }));
      }
    }, 400);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rows, first, inputSearch, dates]);

  const bodyTemplate = (rowData: any) => (
    <div className="flex gap-2 align-items-center">
      <div>{IconPackageCustom}</div>
      <div>{rowData.name}</div>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex gap-3 justify-content-between">
        <div>
          <Button
            label="Nhập từ Excel"
            onClick={() => {
              setmodelOpenImportExcel(true);
            }}
            style={{ height: "35px" }}
            disabled={!selectArrayPermisstion?.includes("IMPORT_EXCEL")}
          />
          <Button
            label="Quản lý file tải lên"
            onClick={() => {
              setVisible(true);
            }}
            className="ml-3"
            style={{ height: "35px" }}
            disabled={!selectArrayPermisstion?.includes("IMPORT_EXCEL")}
          />
        </div>

        <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0">
          <IconField iconPosition="left" className="search-item w-6 flex gap-3">
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm kiện hàng"
              className=" border-1"
              style={{ height: "35px" }}
              onChange={(e) => setInputSearch(e.target.value)}
            />
          </IconField>

          <div className="calendar-container">
            <i className="pi pi-calendar text-500 mr-1" />
            <label
              className="w-max text-500"
              htmlFor="startDate"
              style={{ minWidth: "66px" }}
            >
              Ngày tải:
            </label>
            <div className="card flex justify-content-center">
              <Calendar
                value={dates}
                onChange={(e: any) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                dateFormat="dd/mm/yy"
                locale="vi"
                className="w-full"
                placeholder="Từ ngày - Đến ngày"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-white border-round-md shadow-1 p-3"
        style={{
          height: "fit-content",
          maxHeight: "calc(100vh - 10rem)",
          overflow: "auto",
        }}
      >
        <div className="flex justify-content-between align-items-center mb-2">
          <div></div>
          <div className="flex align-items-center">
            <SelectButton
              value={switchDetail}
              onChange={(e) => {
                setSwitchDetail(e.value);
              }}
              options={switchDetailItems}
            />
          </div>
        </div>
        {switchDetail === "Chi tiết" ? (
          packageData && packageData.data ? (
            <div>
              <DataTable
                value={packageData?.data}
                emptyMessage="Không có dữ liệu"
                size="small"
                className="package-table"
                reorderableColumns
                alwaysShowPaginator={true}
                rowClassName={rowClassName}
                onRowClick={(event) => {
                  setModalDetail(true);
                  setRowData(event.data);
                }}
                scrollable
                scrollHeight="calc(100vh - 20rem)"
                style={{ height: "calc(100vh - 20rem)" }}
              >
                <Column
                  header="STT"
                  body={(rowData, options) => (
                    <div className="text-center">
                      {first + options.rowIndex + 1}
                    </div>
                  )}
                  style={{ width: "5%" }}
                  alignHeader={"center"}
                  align={"center"}
                  frozen
                />
                <Column
                  field="name"
                  header="Tên kiện hàng"
                  style={{ minWidth: "100px" }}
                  body={bodyTemplate}
                ></Column>
                <Column
                  field="carton_no"
                  header="Mã kiện hàng"
                  style={{ minWidth: "100px" }}
                ></Column>
                <Column
                  field="shopee_po_id"
                  header="Shoppe PO ID"
                  style={{ minWidth: "100px" }}
                ></Column>
                <Column
                  field="warehouse_name"
                  header="Kho hàng"
                  align={"center"}
                ></Column>
                <Column
                  header="Số lượng sản phẩm"
                  // 24 nghĩa là đơn hàng này chứa 24 sản phẩm
                  field="amount_product"
                  align={"center"}
                ></Column>
                <Column
                  header="Ngày nhận"
                  body={(rowData) => (
                    <div>{changeDateFormat(rowData?.delivery_date)}</div>
                  )}
                ></Column>
                <Column
                  header="Ghi chú"
                  field="description"
                  align={"center"}
                ></Column>
                <Column
                  header="Trạng thái"
                  field="status"
                  align={"center"}
                  style={{ minWidth: "120px" }}
                  body={statusTemplate}
                />
              </DataTable>
              <div className="card flex justify-content-center align-items-center">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={packageData?.total}
                  onPageChange={onPageChange}
                />
                <Dropdown
                  value={rows}
                  onChange={(e) => setRows(e.value)}
                  options={rowsPerPageOptions}
                  optionLabel="name"
                  optionValue="code"
                  style={{ height: "40px" }}
                />
              </div>
            </div>
          ) : (
            <LoadingSpinner />
          )
        ) : (
          <PlanPackage
            startDate={dates ? dates[0] : null}
            endDate={dates ? dates[1] : null}
          />
        )}
      </div>

      <ModalImportExcel
        visible={modelOpenImportExcel}
        onHide={() => setmodelOpenImportExcel(false)}
        toast={toast}
      />
      <ModalDetailPackage
        visible={modalDetail}
        onHide={() => setModalDetail(false)}
        rowData={rowData}
      />
      <ModalManageUploadFile
        visible={visible}
        onHide={() => setVisible(false)}
      />
    </div>
  );
}
